import { server } from '../utils/server';

const getAllFaq = () =>{
    return server.get(`/faq/getAll`)
    .then((res)=>{
        return res.data;
    })
    .catch((err)=>{
        console.log(err);
    });
}

const faqServices ={
    getAllFaq
}

export default faqServices;